/*
    - Global CSS Module File
*/

.successText {
    color: #388E3C;
}

.dangerText {
    color: #ee2e24;
}

.actionButton {
    margin: 0 0.1rem;
}

.floatRight {
    float: right;
}

.formSectionHeading {
    padding-bottom: 10px;
    border-bottom: 1px solid #E0E0E0;
}

.visitorForm {
    /* padding: 10rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100vw;
    opacity: 0.8;
}

.visitorInnerContainer {
    padding: 1rem;
    width: 80vw;
}

.copyLink :hover {
    cursor: pointer;
}

.centerDiv {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 5rem 10rem;
}

.centerQR {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
    height: auto;
}

.imgCenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}


.centerDivHeading {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.heading {
    text-align: center;
}

.fileUploadButton {
    padding: 0.7rem;
    margin-right: 1rem;
    background-color: #F26739;
    color: white;
    border: 1px solid #F26739;
    border-radius: 0.2rem;
    cursor: pointer;
    box-shadow: 0px 2px 6px 0px rgba(242, 103, 57, 0.5);
    transition: background-color 0.3s, border-color 0.3s;
}

.fileUploadButton:hover {
    background-color: #ce5830;
    border-color: #c2522e;
}

.fileUploadButton [type="file"] {
    /* display: none; */
    /* width: auto; */
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}